@import '~@/themes/func.less';
* {
  box-sizing: border-box;
  &:before {
    box-sizing: border-box;
  }
  &:after {
    box-sizing: border-box;
  }
}

html,
body {
  .font-default;
  font-size: 14px;
  margin: 0;
  width: 100%;
  height: 100%;
}

ul {
  list-style-type: none;
  margin: 0;
}

html {
  &.mobile {
    font-size: 12px;
  }
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #041424;
  color: white;
  overflow-x: auto;
  // .remove-scrollbar();

  --duration: 0.5s;
  --timing: ease;

  transition: color var(--duration) var(--timing), background-color var(--duration) var(--timing);
}

a {
  color: var(--link-color);
  .selected {
    color: var(--link-selected-color);
  }
  &:hover {
    color: @primary-color;
  }
}

::selection {
  background: @primary-color;
}

// custom scrollbar
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

// scroll bg
::-webkit-scrollbar-track {
  // color: @scroll-bar;
  background: transparent;
  // background-color: @scroll-bar;
}

// scroll bar
::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: @scroll-bar;
}

.ant-spin-blur {
  &:after {
    background-color: @bg5;
    opacity: 0;
  }
}

.mobile > body {
  height: 100vh;
  height: -webkit-fill-available;
  overflow: hidden;
  position: fixed;
  left: 0;
  top: 0;
}

.number-value-container {
  display: flex;
  align-items: baseline;
}

// hide number input default up/down button
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

#root {
  height: 100%;
  width: 100%;
  & > div {
    height: 100%;
    width: 100%;
    .App {
      height: 100%;
      width: 100%;
    }
  }
}
