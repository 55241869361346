// define theme color
// @import './antd.customize.less';

@colors: {
  light3: rgba(255, 255, 255, 0.6);
  light4: rgba(255, 255, 255, 0.7);
  light5: #ffffff;
  light6: rgba(27, 31, 38, 0.02);
  dark4: #363d4d; //#292F3A
  dark5: #2d333d; //#22242A
  dark6: rgba(72, 77, 90, 1);
  dark7: rgba(44, 44, 44, 1); //#2C2C2C
  dark8: #1b1f26;
  gray1: #6c7280;
  gray3: #a1a7b2;
  gray4: #f2f7f7;
  gray5: #ebf2f2;
  white: #ffffff;
  black: #000000;
  black2: #222429;
  primary: #29b5bc;

  primary2: #1f8599;
  primary-bg: rgba(41, 181, 188, 0.07);
  secondary: #6c7280;
  blue1: #29b5bc;
  danger: #ff4d6a;
  warning: #ffaa00;
  success: #00bf80;
  green1: #52c6cc;
  green2: #00bf80;
  green3: #039da5;
  green4: #00bf00;
  red1: #ff4d6a;
  red2: #ff4d5b;
  favorite: #ffaa00;
  linear1: linear-gradient(180deg, #003133 0%, #1b1f26 50.52%);
  linear-btn: linear-gradient(to right, #b294ff, #57e6e6);
  primary_hover: #2fd1d9;
  primary_block_hover: #4dc7c9;
  success_hover: #00d991;
  secondary_hover: #363d4d;
  danger_hover: #ff6680;
  primary_hover_bg: rgba(41, 181, 188, 0.1);
  success_hover_bg: rgba(0, 191, 128, 0.1);
  danger_hover_bg: rgba(255, 77, 106, 0.1);
  ghost_disabled: #252b36;
  solid_disabled: #474d59;
  input-gray: #f7fafa;
  linear_gradient_black: linear-gradient(180deg, #2d333d 0%, #262b33 100%);

  // theme mapping

  bg-light: #ffffff;
  bg-dark: #1b1f26;

  body-bg-light: linear-gradient(180deg, #f2f7f7 0%, #ffffff 100%);
  body-bg-dark: linear-gradient(180deg, #22262e 0%, #1b1f26 100%);

  primary-nav-light: #fff;
  primary-nav-dark: #13161c;

  nav-border-light: #f0f4f5;
  nav-border-dark: #13161c;

  second-nav-light: #fafcfc;
  second-nav-dark: #1b1f26;

  primary-border-light: #dfe4e5;
  primary-border-dark: #363d4d;

  second-border-light: #f0f4f5;
  second-border-dark: #252b36;

  third-border-light: #a1a7b2;
  third-border-dark: #363d4d;

  primary-text-light: #1b1f26;
  primary-text-dark: #fff;
  // If the button is solid, the text color is reversed
  primary-solid-text-light: #fff;
  primary-solid-text-dark: #1b1f26;

  second-text-light: #a1a7b2;
  second-text-dark: #6c7280;

  third-text-light: #1b1f26;
  third-text-dark: #6c7280;

  icon-light: #a1a7b2;
  icon-dark: #fff;

  icon-reverse-light: #fff;
  icon-reverse-dark: #6c7280;

  icon-1-light: #a1a7b2;
  icon-1-dark: #475266;

  icon-2-light: #1b1f26;
  icon-2-dark: #ffffff;

  icon-4-light: #dfe4e5;
  icon-4-dark: #6c7280;

  icon-5-light: #a1a7b2;
  icon-5-dark: #6c7280;

  dialog-title-bg-light: linear-gradient(180deg, #ffffff 0%, #f2f7f7 100%);
  dialog-title-bg-dark: linear-gradient(180deg, #2d333d 0%, #262b33 100%);

  dialog-content-bg-light: #fafcfc;
  dialog-content-bg-dark: #2d333d;

  dialog-content-text-light: #6c7280;
  dialog-content-text-dark: #fff;

  text-bg-light: rgba(0, 191, 0, 0.1);
  text-bg-dark: rgba(0, 191, 128, 0.1);

  text-bg-short-light: rgba(255, 77, 91, 0.1);
  text-bg-short-dark: rgba(255, 77, 91, 0.1);

  switch-btn-bg-light: #fff;
  switch-btn-bg-dark: #363d4d;

  switch-icon-light: #363d4d;
  switch-icon-dark: #fff;

  switch-btn-active-bg-light: rgba(41, 181, 188, 0.1);
  switch-btn-active-bg-dark: rgba(41, 181, 188, 0.07);

  form-bg-light: #fff;
  form-bg-dark: #2d333d;

  form-content-bg-light: #fafcfc;
  form-content-bg-dark: #1b1f26;

  pair-banner-bg-light: linear-gradient(180deg, #c2f2f2 0%, #e6ffe6 100%);
  pair-banner-bg-dark: linear-gradient(180deg, #142e33 0%, #131d26 100%);

  pair-banner-footer-bg-light: #fff;
  pair-banner-footer-bg-dark: #13161c;

  scroll-bar-light: #dfe4e5;
  scroll-bar-dark: #363d4d;
  // slider
  slider-bar-dot-light: #dfe4e5;
  slider-bar-dot-dark: #475266;

  card-wrapper-footer-bg: linear-gradient(180deg, #ffffff 0%, #f2f7f7 100%);

  search-input-bg-light: #ebf2f2;
  search-input-bg-dark: #1b1f26;

  search-input-show-bg-light: #fff;
  search-input-show-bg-dark: #1b1f26;
  // chart
  chart-tooltip-background-light: rgba(255, 255, 255, 0.9);
  chart-tooltip-background-dark: rgba(0, 0, 0, 0.8);

  // button
  btn-disabled-bg-light: #dadee6;
  btn-disabled-text-dark: rgba(255, 255, 255, 0.2);

  //pair card
  pair-card-hover-bg-light: linear-gradient(180deg, #e6feff 0%, #ffffff 50.52%);
  pair-card-hover-bg-dark: linear-gradient(180deg, #0a3333 0%, #1b1f26 50%);

  //pair card sub
  pair-card-sub-bg-dark: #22262e;

  // ripple wrapper
  use-1-light: #e9f7f7;
  use-2-light: #f3fbfc;
  use-3-light: #ffffff;
  use-1-dark: #1c2b32;
  use-2-dark: #1b242b;
  use-3-dark: #1b1f26;
  ripple-wrap-bg-light: linear-gradient(180deg, #ffffff 0%, #daf2f2 30%, #fff 50%);
  ripple-wrap-bg-dark: linear-gradient(180deg, #1b1f26 0%, #1d3138 30%, #1b1f26 50%);

  //position card bg
  position-bg-light: linear-gradient(180deg, rgba(41, 181, 188, 0.07) 0%, rgba(41, 181, 188, 0) 50%);

  card-wrapper-sub-bg-dark: #252b36;
  card-wrapper-sub-bg-light: #f0f4f5;

  // mobile-tab-bg:
  mobile-tab-bg-light: #f0f4f5;
  mobile-tab-bg-dark: #252b36;

  // expiry
  expiry-bg-light: #ebf2f2;
  expiry-bg-dark: #1b1f26;
  // campaign
  campaign-bar-bg-light: rgba(255, 255, 255, 0.3);
  campaign-bar-bg-dark: rgba(27, 31, 38, 0.3);

  // leadership
  invitation-bg-dark: linear-gradient(180deg, rgba(54, 61, 77, 0.4) 0%, rgba(54, 61, 77, 0) 100%);
  invitation-bg-light: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%);
  invitation-border-light: rgba(255, 255, 255, 0.4);
  invitation-border-dark: rgba(54, 61, 77, 0.4);
  invitation-filter-light: 0px 10px 20px rgba(75, 76, 77, 0.03);
  invitation-filter-dark: 0px 10px 20px rgba(0, 0, 0, 0.15);
  nft-message-bg-light: #f0f4f5;
  nft-message-bg-dark: #363d4d;
  rule-arrow-light: #a1a7b2;
  rule-arrow-dark: #6c7280;
  leader-ship-border-dark: #363d4d;
  leader-ship-border-light: #dfe4e5;
  leader-ship-header-border-dark: #363d4d;
  leader-ship-header-border-light: #f0f4f5;
  invitation-input-bg-light: rgba(255, 255, 255, 0.4);
  invitation-input-bg-dark: rgba(27, 31, 38, 0.4);
  nft-skeleton-bg-light: linear-gradient(90deg, #e7eced 48.96%, #f5f9fa 84.11%, #e7eced 100%);
  nft-skeleton-bg-dark: linear-gradient(90deg, #2d333d 48.96%, #363d4c 82.81%, #2d333d 100%);
  leader-btn-bg-dark: #1b1f26;
  leader-btn-bg-light: #475266;
  leader-container-bg-light: #ffffff;
  leader-container-bg-dark: #252b36;
  leader-title-bg-light: linear-gradient(180deg, #196e73 0%, #142933 100%), #1b1f26;
  leader-title-bg-dark: linear-gradient(198.25deg, #e6fff7 12.4%, #66ffff 87.6%), #ffffff;
  header-dropdown-shadow-dark: 0px 10px 20px rgba(0, 0, 0, 0.15);
  header-dropdown-shadow-light: 0px 10px 20px rgba(75, 76, 77, 0.03);
  // guidance
  guidance-close-bg-dark: rgba(0, 0, 0, 0.2);
  guidance-close-bg-light: rgba(0, 0, 0, 0.1);
};

// public color
.base-color {
  // .antd-reset(@colors);
  // base color
  --primary-color: @colors[ primary ];
  --primary-secondary-color: @colors[ primary2 ];
  --secondary-color: @colors[ secondary ];
  --danger-color: @colors[ danger ];
  --warning-color: @colors[ warning ];
  --success-color: @colors[ success ];
  //border
  --border-color: @colors[ dark4 ];
  --btn-linear-border-color: @colors[ linear-btn ];
  //text
  --text-color: @colors[ dark8 ];
  --text-gray-color: @colors[ gray1 ];
  --text-gray-color2: @colors[ gray3 ];

  --text4: @colors[ light4];
  --text3: @colors[ green1 ];
  --text2: @colors[ gray3 ];
  --text-chart-label: @colors[light3];
  --text-chart-label-solid: @colors[primary-solid-text-light];
  //link
  --link-color: @colors [primary];
  --link-selected-color: @colors [primary];
  //modal
  --modal-close-color: @colors[ dark4 ];
  --green1: @colors[ green1 ];
  --green2: @colors[ green2 ];
  --green4: @colors[ green4 ];
  --red1: @colors[ red1 ];
  --red2: @colors[ red2 ];
  --blue1: @colors[ blue1 ];
  --favorite: @colors[favorite];
  //status
  --primary_hover: @colors[primary_hover];
  --primary_block_hover: @colors[primary_block_hover];
  --secondary-color: @colors[ secondary ];
  --secondary_hover: @colors[secondary_hover];
  --success_hover: @colors[success_hover];
  --danger_hover: @colors[danger_hover];
  --primary_hover_bg: @colors[primary_hover_bg];
  --secondary_hover_bg: @colors[primary_hover_bg];
  --success_hover_bg: @colors[success_hover_bg];
  --danger_hover_bg: @colors[danger_hover_bg];
  --ghost_disabled: @colors[ghost_disabled];
  --solid_disabled: @colors[solid_disabled];
  --card-wrapper-sub-bg: @colors[card-wrapper-sub-bg-light];
  // input
  --input-background: @colors[second-border-dark];
  --input-background-focus: @colors[dark8];

  // button
  --trade-buy-button: @colors[ green4 ];
  --trade-sell-button: @colors[ red2 ];
  --btn-disabled-background: @colors[btn-disabled-bg-light];
  --btn-disabled-text-color: @colors[white];
  // bg
  --primary-bg: @colors[ primary-bg ];
  --card-wrapper-footer-bg: @colors[card-wrapper-footer-bg];
  --body-bg: @colors[body-bg-light];
  // chart
  --chart-tooltip-background: @colors[chart-tooltip-background-light];

  //slider
  --slider-bar-dot: @colors[slider-bar-dot-light];

  // change
  --lose-color: #ff8c8c;
  --gain-color: #00cc88;
}

.light-theme {
  .base-color();
  color-scheme: light;
  // height
  --doc-height: 100%; // doc window height, for fix mobile height
  //   text
  --text-primary-color: @colors[ primary-text-light ];
  --text-primary-solid-color: @colors[ primary-solid-text-light ];
  --text-chart-label: @colors[gray3];
  --text-chart-label-solid: @colors[dark8];
  --secondary-text: @colors[ second-text-light ];
  --third-text: @colors[ third-text-light ];
  // linear-gradient-bg
  --linear_gradient_bg_1: @colors[white];
  // layout
  --layout-header-height: 32px;

  //bg
  --menu-bg: transparent;
  --bg: @colors[ bg-light ];
  --bg1: @colors[ primary-nav-light ];
  --bg2: @colors[ second-nav-light];
  --bg3: @colors[ light3 ];
  --bg4: @colors[ light4 ];
  --bg5: @colors[ light5 ];
  --bg6: @colors[ light5 ];
  --bg7: @colors[ light5 ];
  --bg8: @colors[ light5 ];
  --bgl: @colors[ light5 ];
  --bg9: @colors[ light6 ];
  --bg10: @colors[ gray5 ];
  --pair-card-hover-bg: @colors[pair-card-hover-bg-light];
  --bgPairTitle: @colors[second-border-light];
  --primary-nav-bg: @colors[ primary-nav-light ];
  --dialog-title-bg: @colors[ dialog-title-bg-light ];
  --dialog-content-bg: @colors[ dialog-content-bg-light ];
  --pair-banner-bg: @colors[ pair-banner-bg-light ];
  --pair-banner-footer-bg: @colors[ pair-banner-footer-bg-light ];
  --scroll-bar: @colors[ scroll-bar-light ];

  //input
  --input-background: @colors[input-gray];
  --input-background-focus: @colors[white];

  --card-wrapper-footer-bg: @colors[card-wrapper-footer-bg];
  --card-background: @colors[white];

  // text
  --text-bg: @colors[ text-bg-light ];
  --text-green: @colors[ green4 ];
  --text-bg-short: @colors[ text-bg-short-light ];
  --dialog-content-text: @colors[ dialog-content-text-light ];

  // border
  --input-border-color: @colors[ primary-border-light ];
  --input-border-hover-color: @colors[ primary];
  --border-color: @colors[ primary-border-light ];
  --second-border-color: @colors[second-border-light];
  --third-border-color: @colors[third-border-light];
  --nav-border: @colors[nav-border-light];

  // switch
  --switch-slider-bg: @colors[ gray3 ];
  --switch-border-color: @colors[ gray3 ];
  --switch-bg: @colors[ white ];
  --switch-btn-bg: @colors[ switch-btn-bg-light ];
  --switch-icon: @colors[ switch-icon-light ];
  --switch-btn-active-bg: @colors[ switch-btn-active-bg-light ];
  // icon
  --icon-color: @colors[ icon-light ];
  --icon-reverse-color: @colors[ icon-reverse-light ];
  --icon-1-color: @colors[ icon-1-light ];
  --icon-2-color: @colors[ icon-2-light ];
  --icon-4-color: @colors[ icon-4-light ];
  --icon-5-color: @colors[ icon-5-light ];
  --form-bg: @colors[ form-bg-light ];
  --form-content-bg: @colors[ form-content-bg-light ];

  --search-input-bg: @colors[ search-input-bg-light ];
  --search-input-show-bg: @colors[ search-input-show-bg-light ];
  //ripple
  --use-1: @colors[use-1-light];
  --use-2: @colors[use-2-light];
  --use-3: @colors[use-3-light];
  --ripple-wrap-bg: @colors[ripple-wrap-bg-light];

  // position card
  --position-card-bg: @colors[position-bg-light];

  --mobile-tab-bg: @colors[mobile-tab-bg-light];

  --expiry-bg: @colors[ expiry-bg-light ];

  // campaign
  --campaign-bar-bg: @colors[ campaign-bar-bg-light];
  --campaign-bar-hover: @colors[white];

  // leadership
  --invitation-bg: @colors[invitation-bg-light];
  --invitation-border: @colors[invitation-border-light];
  --invitation-filter: @colors[invitation-filter-light];
  --nft-message-bg: @colors[nft-message-bg-light];
  --rule-arrow-bg: @colors[rule-arrow-light];
  --leader-ship-border: @colors[leader-ship-border-light];
  --invitation-input-bg: @colors[invitation-input-bg-light];
  --nft-skeleton-bg: @colors[nft-skeleton-bg-light];
  --leader-btn-bg: @colors[leader-btn-bg-light];
  --leader-container-bg: @colors[leader-container-bg-light];
  --leader-title-bg: @colors[leader-title-bg-light];
  --leader-ship-header-border: @colors[leader-ship-header-border-light];
  --header-dropdown-shadow: @colors[header-dropdown-shadow-light];
  // guidance
  --guidance-close-bg: @colors[guidance-close-bg-light];
}

.dark-theme {
  .base-color();
  color-scheme: dark;
  //   link
  --link-color: @colors[ white ];
  --link-selected-color: @colors[ primary ];
  --link-hover: @colors[ white ];
  --link-visited: @colors[ white ];
  --border-color: @colors[ primary-border-dark ];
  // text
  --text-primary-solid-color: @colors[ primary-solid-text-dark ];
  --secondary-text: @colors[ second-text-dark ];
  --third-text: @colors[ third-text-dark ];
  --secondary-color: @colors[ white];

  --text-blue: #59f;
  --text2: @colors[gray1];
  --text3: @colors[green1];
  --text4: @colors[light4];
  --text-color: @colors[ white ];
  --text-color-black: @colors[ dark8 ];
  --text-gray-color: @colors[ gray1 ];
  --text-primary-color: @colors[white];
  --text-bg: @colors[ text-bg-dark ];
  --text-bg-short: @colors[ text-bg-short-dark ];
  --dialog-content-text: @colors[ dialog-content-text-dark ];
  // ticks
  --tick-body: #e5e5e5;
  --tick-active: #00bfbf;
  //modal
  --modal-close-color: @colors[ white ];
  --campaign-bar-hover: @colors[white];

  --nav-border: @colors[nav-border-dark];

  // bg
  --bg: @colors[ bg-dark ];
  --bg1: @colors[ primary-nav-dark ];
  --bg2: @colors[ second-nav-dark ];
  --bg4: @colors[ dark4 ];
  --bg5: @colors[ dark5 ];
  --bg6: @colors[ dark6 ];
  --bg7: @colors[ dark7 ];
  --bg8: @colors[ green3 ];
  --bgl: @colors[ linear1 ];
  --bg9: @colors[ black2 ];
  --bg10: @colors[ dark8 ];
  --pair-card-hover-bg: @colors[pair-card-hover-bg-dark];
  --bgPairTitle: @colors[second-border-dark];
  --primary-nav-bg: @colors[ primary-nav-dark ];
  --dialog-title-bg: @colors[ dialog-title-bg-dark ];
  --dialog-content-bg: @colors[ dialog-content-bg-dark ];
  --pair-banner-bg: @colors[ pair-banner-bg-dark ];
  --pair-banner-footer-bg: @colors[ pair-banner-footer-bg-dark ];
  --scroll-bar: @colors[ scroll-bar-dark ];
  --card-background: @colors[dark5];
  --card-wrapper-footer-bg: none;
  --card-wrapper-sub-bg: @colors[card-wrapper-sub-bg-dark];
  --body-bg: @colors[body-bg-dark];

  // bg-linear-gradient
  --linear_gradient_bg_1: @colors[linear_gradient_black];
  // border
  --input-border-color: var(--border-color);
  --second-border-color: @colors[second-border-dark];
  --third-border-color: @colors[third-border-dark];

  // switch
  --switch-slider-bg: @colors[ white ];
  --switch-border-color: @colors[ white ];
  --switch-bg: @colors[ second-nav-dark ];
  --switch-btn-bg: @colors[ switch-btn-bg-dark ];
  --switch-icon: @colors[ switch-icon-dark ];
  --switch-btn-active-bg: @colors[ switch-btn-active-bg-dark ];

  // icon
  --icon-color: @colors[ icon-dark ];
  --icon-reverse-color: @colors[ icon-reverse-dark ];
  --icon-1-color: @colors[ icon-1-dark ];
  --icon-2-color: @colors[ icon-2-dark ];
  --icon-4-color: @colors[ icon-4-dark ];
  --icon-5-color: @colors[ icon-5-dark ];
  --form-bg: @colors[ form-bg-dark ];
  --form-content-bg: @colors[ form-content-bg-dark ];

  --search-input-bg: @colors[ search-input-bg-dark ];
  --search-input-show-bg: @colors[ search-input-show-bg-dark ];
  // chart
  --chart-tooltip-background: @colors[chart-tooltip-background-dark];

  //button
  --btn-disabled-background: @colors[solid_disabled];
  --btn-disabled-text-color: @colors[btn-disabled-text-dark];

  //slider
  --slider-bar-dot: @colors[slider-bar-dot-dark];

  //ripple
  --use-1: @colors[use-1-dark];
  --use-2: @colors[use-2-dark];
  --use-3: @colors[use-3-dark];
  --ripple-wrap-bg: @colors[ripple-wrap-bg-dark];

  //position
  --position-card-bg: @colors[position-bg-light];

  --mobile-tab-bg: @colors[mobile-tab-bg-dark];

  --expiry-bg: @colors[ expiry-bg-dark ];

  // campaign
  --campaign-bar-bg: @colors[ campaign-bar-bg-dark];
  --campaign-bar-hover: @colors[black];

  // leadership
  --invitation-bg: @colors[invitation-bg-dark];
  --invitation-border: @colors[invitation-border-dark];
  --invitation-border: @colors[invitation-border-dark];
  --nft-message-bg: @colors[nft-message-bg-dark];
  --rule-arrow-bg: @colors[rule-arrow-dark];
  --leader-ship-border: @colors[leader-ship-border-dark];
  --invitation-input-bg: @colors[invitation-input-bg-dark];
  --nft-skeleton-bg: @colors[nft-skeleton-bg-dark];
  --leader-btn-bg: @colors[leader-btn-bg-dark];
  --leader-container-bg: @colors[leader-container-bg-dark];
  --leader-title-bg: @colors[leader-title-bg-dark];
  --leader-ship-header-border: @colors[leader-ship-header-border-dark];
  --header-dropdown-shadow: @colors[header-dropdown-shadow-dark];
  // guidance
  --guidance-close-bg: @colors[guidance-close-bg-dark];
}

:root {
  .light-theme();
}

@media (prefers-color-scheme: dark) {
  :root {
    .dark-theme();
  }
}

html[data-theme='light-theme'] {
  .light-theme();
}

// TODO: dark mode
html[data-theme='dark-theme'] {
  .dark-theme();
}
