.test {
  background-color: black;
}

.main-layout {
  background-image: url('./assets/bg.png');
  width: 100%;
  height: 100%;
  background-size: cover;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  .main-title {
    position: absolute;
    top: 15vh;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    svg {
      max-width: 400px;
    }
    .main-sub-title {
      margin-top: 32px;
      margin-bottom: 0;
      font-weight: 400;
      font-size: 24px;
      line-height: 24px;
      white-space: nowrap;
    }
    .main-light {
      position: absolute;
      top: 0;
      min-width: 600px;
      z-index: -1;
      img {
        // width: 100%;
      }
    }
  }
  footer {
    // background: linear-gradient(180deg, rgba(3, 12, 26, 0.8) 0%, rgba(3, 12, 26, 0.6) 100%);
    background: linear-gradient(180deg, rgba(3, 12, 26, 0) 0%, rgba(3, 12, 26, 0.6) 100%);
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .footer-intro {
      margin: 0;
      max-width: 1920px;
      min-width: 1800px;
      padding: 64px;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      text-align: left;
      text-indent: 2em;
      & > div {
        margin-bottom: 8px;
      }
      display: flex;
      justify-content: space-around;
      align-items: stretch;
      gap: 16px;
      &-container {
        width: 100%;
        overflow-x: auto;
        display: flex;
        justify-content: center;
        // scroll bar
        &::-webkit-scrollbar-thumb {
          height: 1px;
          // background: transparent;
        }
      }
      &-card {
        max-width: 365px;
        background: linear-gradient(180deg, rgba(3, 12, 26, 0.8) 0%, rgba(3, 12, 26, 0.6) 100%);
        border-radius: 32px;
        padding: 32px;
        flex: 1;
        &:hover {
          background: linear-gradient(180deg, rgba(3, 12, 26, 0.75) 0%, rgba(3, 12, 26, 0.6) 100%);
        }
        &-icon {
          width: 28px;
          height: 28px;
          margin-bottom: 32px;
          display: flex;
          & > svg {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .footer-contact {
      border-top: 1px solid rgba(255, 255, 255, 0.2);
      padding: 32px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      a {
        margin-left: 10px;
      }
    }
  }
  &.mobile {
    .main-title {
      svg {
        max-width: 240px;
      }
      .main-sub-title {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
      }
    }
    footer {
      position: fixed;
      bottom: 0;
      width: 100%;

      .footer-intro {
        padding: 40px 0;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        min-width: auto;
        width: fit-content;
        &-container {
          width: 100%;
          margin-top: 40vh;
          justify-content: flex-start;
        }

        &-card {
          max-width: 280px;
          width: 280px;
          // height: 304px;
        }
      }
    }
  }

  @media screen and (min-height: 600px) and (max-height: 768px) {
    footer {
      .footer-intro {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        padding-bottom: 16px;
      }
      .footer-contact {
        padding: 16px;
      }
    }
  }

  @media screen and (max-width: 1800px) {
    footer {
      .footer-intro {
        &-container {
          justify-content: flex-start;
          font-weight: 400;
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
  }
}

@primary-color: #29b5bc;@link-color: #29b5bc;@hack: true; @import '~@/themes/variable.less'; @import '~@/themes/font.less';