// @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;900&display=swap');
// Declare mixin
.font(@fontFamily: Poppins; @fontWeight: normal; @fontStyle: normal) {
  font-family: @fontFamily, 'Roboto', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-style: @fontStyle;
  // font-weight: @fontWeight;
}

.font-default {
  .font();
}
.font-number {
  .font(DINPro);
}
.font12 {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.font14 {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.font16 {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
}

.font20 {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.font24 {
  font-weight: 600;
  font-size: 22px;
  line-height: 24px;
}

.font24 {
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
}

.font32 {
  font-weight: 600;
  font-size: 32px;
  line-height: 32px;
}
