.font-default {
  font-family: Poppins, 'Roboto', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-style: normal;
}
.font-number {
  font-family: DINPro, 'Roboto', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-style: normal;
}
.font12 {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}
.font14 {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}
.font16 {
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
}
.font20 {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}
.font24 {
  font-weight: 600;
  font-size: 22px;
  line-height: 24px;
}
.font24 {
  font-weight: 600;
  font-size: 24px;
  line-height: 24px;
}
.font32 {
  font-weight: 600;
  font-size: 32px;
  line-height: 32px;
}
.base-color {
  --primary-color: #29b5bc;
  --primary-secondary-color: #1f8599;
  --danger-color: #ff4d6a;
  --warning-color: #ffaa00;
  --success-color: #00bf80;
  --border-color: #363d4d;
  --btn-linear-border-color: linear-gradient(to right, #b294ff, #57e6e6);
  --text-color: #1b1f26;
  --text-gray-color: #6c7280;
  --text-gray-color2: #a1a7b2;
  --text4: rgba(255, 255, 255, 0.7);
  --text3: #52c6cc;
  --text2: #a1a7b2;
  --text-chart-label: rgba(255, 255, 255, 0.6);
  --text-chart-label-solid: #fff;
  --link-color:  [primary];
  --link-selected-color:  [primary];
  --modal-close-color: #363d4d;
  --green1: #52c6cc;
  --green2: #00bf80;
  --green4: #00bf00;
  --red1: #ff4d6a;
  --red2: #ff4d5b;
  --blue1: #29b5bc;
  --favorite: #ffaa00;
  --primary_hover: #2fd1d9;
  --primary_block_hover: #4dc7c9;
  --secondary-color: #6c7280;
  --secondary_hover: #363d4d;
  --success_hover: #00d991;
  --danger_hover: #ff6680;
  --primary_hover_bg: rgba(41, 181, 188, 0.1);
  --secondary_hover_bg: rgba(41, 181, 188, 0.1);
  --success_hover_bg: rgba(0, 191, 128, 0.1);
  --danger_hover_bg: rgba(255, 77, 106, 0.1);
  --ghost_disabled: #252b36;
  --solid_disabled: #474d59;
  --card-wrapper-sub-bg: #f0f4f5;
  --input-background: #252b36;
  --input-background-focus: #1b1f26;
  --trade-buy-button: #00bf00;
  --trade-sell-button: #ff4d5b;
  --btn-disabled-background: #dadee6;
  --btn-disabled-text-color: #ffffff;
  --primary-bg: rgba(41, 181, 188, 0.07);
  --card-wrapper-footer-bg: linear-gradient(180deg, #ffffff 0%, #f2f7f7 100%);
  --body-bg: linear-gradient(180deg, #f2f7f7 0%, #ffffff 100%);
  --chart-tooltip-background: rgba(255, 255, 255, 0.9);
  --slider-bar-dot: #dfe4e5;
  --lose-color: #ff8c8c;
  --gain-color: #00cc88;
}
.light-theme {
  --primary-color: #29b5bc;
  --primary-secondary-color: #1f8599;
  --danger-color: #ff4d6a;
  --warning-color: #ffaa00;
  --success-color: #00bf80;
  --border-color: #363d4d;
  --btn-linear-border-color: linear-gradient(to right, #b294ff, #57e6e6);
  --text-color: #1b1f26;
  --text-gray-color: #6c7280;
  --text-gray-color2: #a1a7b2;
  --text4: rgba(255, 255, 255, 0.7);
  --text3: #52c6cc;
  --text2: #a1a7b2;
  --text-chart-label: rgba(255, 255, 255, 0.6);
  --text-chart-label-solid: #fff;
  --link-color:  [primary];
  --link-selected-color:  [primary];
  --modal-close-color: #363d4d;
  --green1: #52c6cc;
  --green2: #00bf80;
  --green4: #00bf00;
  --red1: #ff4d6a;
  --red2: #ff4d5b;
  --blue1: #29b5bc;
  --favorite: #ffaa00;
  --primary_hover: #2fd1d9;
  --primary_block_hover: #4dc7c9;
  --secondary-color: #6c7280;
  --secondary_hover: #363d4d;
  --success_hover: #00d991;
  --danger_hover: #ff6680;
  --primary_hover_bg: rgba(41, 181, 188, 0.1);
  --secondary_hover_bg: rgba(41, 181, 188, 0.1);
  --success_hover_bg: rgba(0, 191, 128, 0.1);
  --danger_hover_bg: rgba(255, 77, 106, 0.1);
  --ghost_disabled: #252b36;
  --solid_disabled: #474d59;
  --card-wrapper-sub-bg: #f0f4f5;
  --input-background: #252b36;
  --input-background-focus: #1b1f26;
  --trade-buy-button: #00bf00;
  --trade-sell-button: #ff4d5b;
  --btn-disabled-background: #dadee6;
  --btn-disabled-text-color: #ffffff;
  --primary-bg: rgba(41, 181, 188, 0.07);
  --body-bg: linear-gradient(180deg, #f2f7f7 0%, #ffffff 100%);
  --chart-tooltip-background: rgba(255, 255, 255, 0.9);
  --slider-bar-dot: #dfe4e5;
  --lose-color: #ff8c8c;
  --gain-color: #00cc88;
  color-scheme: light;
  --doc-height: 100%;
  --text-primary-color: #1b1f26;
  --text-primary-solid-color: #fff;
  --text-chart-label: #a1a7b2;
  --text-chart-label-solid: #1b1f26;
  --secondary-text: #a1a7b2;
  --third-text: #1b1f26;
  --linear_gradient_bg_1: #ffffff;
  --layout-header-height: 32px;
  --menu-bg: transparent;
  --bg: #ffffff;
  --bg1: #fff;
  --bg2: #fafcfc;
  --bg3: rgba(255, 255, 255, 0.6);
  --bg4: rgba(255, 255, 255, 0.7);
  --bg5: #ffffff;
  --bg6: #ffffff;
  --bg7: #ffffff;
  --bg8: #ffffff;
  --bgl: #ffffff;
  --bg9: rgba(27, 31, 38, 0.02);
  --bg10: #ebf2f2;
  --pair-card-hover-bg: linear-gradient(180deg, #e6feff 0%, #ffffff 50.52%);
  --bgPairTitle: #f0f4f5;
  --primary-nav-bg: #fff;
  --dialog-title-bg: linear-gradient(180deg, #ffffff 0%, #f2f7f7 100%);
  --dialog-content-bg: #fafcfc;
  --pair-banner-bg: linear-gradient(180deg, #c2f2f2 0%, #e6ffe6 100%);
  --pair-banner-footer-bg: #fff;
  --scroll-bar: #dfe4e5;
  --input-background: #f7fafa;
  --input-background-focus: #ffffff;
  --card-wrapper-footer-bg: linear-gradient(180deg, #ffffff 0%, #f2f7f7 100%);
  --card-background: #ffffff;
  --text-bg: rgba(0, 191, 0, 0.1);
  --text-green: #00bf00;
  --text-bg-short: rgba(255, 77, 91, 0.1);
  --dialog-content-text: #6c7280;
  --input-border-color: #dfe4e5;
  --input-border-hover-color: #29b5bc;
  --border-color: #dfe4e5;
  --second-border-color: #f0f4f5;
  --third-border-color: #a1a7b2;
  --nav-border: #f0f4f5;
  --switch-slider-bg: #a1a7b2;
  --switch-border-color: #a1a7b2;
  --switch-bg: #ffffff;
  --switch-btn-bg: #fff;
  --switch-icon: #363d4d;
  --switch-btn-active-bg: rgba(41, 181, 188, 0.1);
  --icon-color: #a1a7b2;
  --icon-reverse-color: #fff;
  --icon-1-color: #a1a7b2;
  --icon-2-color: #1b1f26;
  --icon-4-color: #dfe4e5;
  --icon-5-color: #a1a7b2;
  --form-bg: #fff;
  --form-content-bg: #fafcfc;
  --search-input-bg: #ebf2f2;
  --search-input-show-bg: #fff;
  --use-1: #e9f7f7;
  --use-2: #f3fbfc;
  --use-3: #ffffff;
  --ripple-wrap-bg: linear-gradient(180deg, #ffffff 0%, #daf2f2 30%, #fff 50%);
  --position-card-bg: linear-gradient(180deg, rgba(41, 181, 188, 0.07) 0%, rgba(41, 181, 188, 0) 50%);
  --mobile-tab-bg: #f0f4f5;
  --expiry-bg: #ebf2f2;
  --campaign-bar-bg: rgba(255, 255, 255, 0.3);
  --campaign-bar-hover: #ffffff;
  --invitation-bg: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%);
  --invitation-border: rgba(255, 255, 255, 0.4);
  --invitation-filter: 0px 10px 20px rgba(75, 76, 77, 0.03);
  --nft-message-bg: #f0f4f5;
  --rule-arrow-bg: #a1a7b2;
  --leader-ship-border: #dfe4e5;
  --invitation-input-bg: rgba(255, 255, 255, 0.4);
  --nft-skeleton-bg: linear-gradient(90deg, #e7eced 48.96%, #f5f9fa 84.11%, #e7eced 100%);
  --leader-btn-bg: #475266;
  --leader-container-bg: #ffffff;
  --leader-title-bg: linear-gradient(180deg, #196e73 0%, #142933 100%), #1b1f26;
  --leader-ship-header-border: #f0f4f5;
  --header-dropdown-shadow: 0px 10px 20px rgba(75, 76, 77, 0.03);
  --guidance-close-bg: rgba(0, 0, 0, 0.1);
}
.dark-theme {
  --primary-color: #29b5bc;
  --primary-secondary-color: #1f8599;
  --danger-color: #ff4d6a;
  --warning-color: #ffaa00;
  --success-color: #00bf80;
  --btn-linear-border-color: linear-gradient(to right, #b294ff, #57e6e6);
  --text-color: #1b1f26;
  --text-gray-color2: #a1a7b2;
  --text2: #a1a7b2;
  --text-chart-label: rgba(255, 255, 255, 0.6);
  --text-chart-label-solid: #fff;
  --link-color:  [primary];
  --link-selected-color:  [primary];
  --modal-close-color: #363d4d;
  --green1: #52c6cc;
  --green2: #00bf80;
  --green4: #00bf00;
  --red1: #ff4d6a;
  --red2: #ff4d5b;
  --blue1: #29b5bc;
  --favorite: #ffaa00;
  --primary_hover: #2fd1d9;
  --primary_block_hover: #4dc7c9;
  --secondary-color: #6c7280;
  --secondary_hover: #363d4d;
  --success_hover: #00d991;
  --danger_hover: #ff6680;
  --primary_hover_bg: rgba(41, 181, 188, 0.1);
  --secondary_hover_bg: rgba(41, 181, 188, 0.1);
  --success_hover_bg: rgba(0, 191, 128, 0.1);
  --danger_hover_bg: rgba(255, 77, 106, 0.1);
  --ghost_disabled: #252b36;
  --solid_disabled: #474d59;
  --card-wrapper-sub-bg: #f0f4f5;
  --input-background: #252b36;
  --input-background-focus: #1b1f26;
  --trade-buy-button: #00bf00;
  --trade-sell-button: #ff4d5b;
  --btn-disabled-background: #dadee6;
  --btn-disabled-text-color: #ffffff;
  --primary-bg: rgba(41, 181, 188, 0.07);
  --card-wrapper-footer-bg: linear-gradient(180deg, #ffffff 0%, #f2f7f7 100%);
  --body-bg: linear-gradient(180deg, #f2f7f7 0%, #ffffff 100%);
  --chart-tooltip-background: rgba(255, 255, 255, 0.9);
  --slider-bar-dot: #dfe4e5;
  --lose-color: #ff8c8c;
  --gain-color: #00cc88;
  color-scheme: dark;
  --link-color: #ffffff;
  --link-selected-color: #29b5bc;
  --link-hover: #ffffff;
  --link-visited: #ffffff;
  --border-color: #363d4d;
  --text-primary-solid-color: #1b1f26;
  --secondary-text: #6c7280;
  --third-text: #6c7280;
  --secondary-color: #ffffff;
  --text-blue: #59f;
  --text2: #6c7280;
  --text3: #52c6cc;
  --text4: rgba(255, 255, 255, 0.7);
  --text-color: #ffffff;
  --text-color-black: #1b1f26;
  --text-gray-color: #6c7280;
  --text-primary-color: #ffffff;
  --text-bg: rgba(0, 191, 128, 0.1);
  --text-bg-short: rgba(255, 77, 91, 0.1);
  --dialog-content-text: #fff;
  --tick-body: #e5e5e5;
  --tick-active: #00bfbf;
  --modal-close-color: #ffffff;
  --campaign-bar-hover: #ffffff;
  --nav-border: #13161c;
  --bg: #1b1f26;
  --bg1: #13161c;
  --bg2: #1b1f26;
  --bg4: #363d4d;
  --bg5: #2d333d;
  --bg6: #484d5a;
  --bg7: #2c2c2c;
  --bg8: #039da5;
  --bgl: linear-gradient(180deg, #003133 0%, #1b1f26 50.52%);
  --bg9: #222429;
  --bg10: #1b1f26;
  --pair-card-hover-bg: linear-gradient(180deg, #0a3333 0%, #1b1f26 50%);
  --bgPairTitle: #252b36;
  --primary-nav-bg: #13161c;
  --dialog-title-bg: linear-gradient(180deg, #2d333d 0%, #262b33 100%);
  --dialog-content-bg: #2d333d;
  --pair-banner-bg: linear-gradient(180deg, #142e33 0%, #131d26 100%);
  --pair-banner-footer-bg: #13161c;
  --scroll-bar: #363d4d;
  --card-background: #2d333d;
  --card-wrapper-footer-bg: none;
  --card-wrapper-sub-bg: #252b36;
  --body-bg: linear-gradient(180deg, #22262e 0%, #1b1f26 100%);
  --linear_gradient_bg_1: linear-gradient(180deg, #2d333d 0%, #262b33 100%);
  --input-border-color: var(--border-color);
  --second-border-color: #252b36;
  --third-border-color: #363d4d;
  --switch-slider-bg: #ffffff;
  --switch-border-color: #ffffff;
  --switch-bg: #1b1f26;
  --switch-btn-bg: #363d4d;
  --switch-icon: #fff;
  --switch-btn-active-bg: rgba(41, 181, 188, 0.07);
  --icon-color: #fff;
  --icon-reverse-color: #6c7280;
  --icon-1-color: #475266;
  --icon-2-color: #ffffff;
  --icon-4-color: #6c7280;
  --icon-5-color: #6c7280;
  --form-bg: #2d333d;
  --form-content-bg: #1b1f26;
  --search-input-bg: #1b1f26;
  --search-input-show-bg: #1b1f26;
  --chart-tooltip-background: rgba(0, 0, 0, 0.8);
  --btn-disabled-background: #474d59;
  --btn-disabled-text-color: rgba(255, 255, 255, 0.2);
  --slider-bar-dot: #475266;
  --use-1: #1c2b32;
  --use-2: #1b242b;
  --use-3: #1b1f26;
  --ripple-wrap-bg: linear-gradient(180deg, #1b1f26 0%, #1d3138 30%, #1b1f26 50%);
  --position-card-bg: linear-gradient(180deg, rgba(41, 181, 188, 0.07) 0%, rgba(41, 181, 188, 0) 50%);
  --mobile-tab-bg: #252b36;
  --expiry-bg: #1b1f26;
  --campaign-bar-bg: rgba(27, 31, 38, 0.3);
  --campaign-bar-hover: #000000;
  --invitation-bg: linear-gradient(180deg, rgba(54, 61, 77, 0.4) 0%, rgba(54, 61, 77, 0) 100%);
  --invitation-border: rgba(54, 61, 77, 0.4);
  --nft-message-bg: #363d4d;
  --rule-arrow-bg: #6c7280;
  --leader-ship-border: #363d4d;
  --invitation-input-bg: rgba(27, 31, 38, 0.4);
  --nft-skeleton-bg: linear-gradient(90deg, #2d333d 48.96%, #363d4c 82.81%, #2d333d 100%);
  --leader-btn-bg: #1b1f26;
  --leader-container-bg: #252b36;
  --leader-title-bg: linear-gradient(198.25deg, #e6fff7 12.4%, #66ffff 87.6%), #ffffff;
  --leader-ship-header-border: #363d4d;
  --header-dropdown-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  --guidance-close-bg: rgba(0, 0, 0, 0.2);
}
:root {
  --primary-color: #29b5bc;
  --primary-secondary-color: #1f8599;
  --danger-color: #ff4d6a;
  --warning-color: #ffaa00;
  --success-color: #00bf80;
  --border-color: #363d4d;
  --btn-linear-border-color: linear-gradient(to right, #b294ff, #57e6e6);
  --text-color: #1b1f26;
  --text-gray-color: #6c7280;
  --text-gray-color2: #a1a7b2;
  --text4: rgba(255, 255, 255, 0.7);
  --text3: #52c6cc;
  --text2: #a1a7b2;
  --text-chart-label: rgba(255, 255, 255, 0.6);
  --text-chart-label-solid: #fff;
  --link-color:  [primary];
  --link-selected-color:  [primary];
  --modal-close-color: #363d4d;
  --green1: #52c6cc;
  --green2: #00bf80;
  --green4: #00bf00;
  --red1: #ff4d6a;
  --red2: #ff4d5b;
  --blue1: #29b5bc;
  --favorite: #ffaa00;
  --primary_hover: #2fd1d9;
  --primary_block_hover: #4dc7c9;
  --secondary-color: #6c7280;
  --secondary_hover: #363d4d;
  --success_hover: #00d991;
  --danger_hover: #ff6680;
  --primary_hover_bg: rgba(41, 181, 188, 0.1);
  --secondary_hover_bg: rgba(41, 181, 188, 0.1);
  --success_hover_bg: rgba(0, 191, 128, 0.1);
  --danger_hover_bg: rgba(255, 77, 106, 0.1);
  --ghost_disabled: #252b36;
  --solid_disabled: #474d59;
  --card-wrapper-sub-bg: #f0f4f5;
  --input-background: #252b36;
  --input-background-focus: #1b1f26;
  --trade-buy-button: #00bf00;
  --trade-sell-button: #ff4d5b;
  --btn-disabled-background: #dadee6;
  --btn-disabled-text-color: #ffffff;
  --primary-bg: rgba(41, 181, 188, 0.07);
  --body-bg: linear-gradient(180deg, #f2f7f7 0%, #ffffff 100%);
  --chart-tooltip-background: rgba(255, 255, 255, 0.9);
  --slider-bar-dot: #dfe4e5;
  --lose-color: #ff8c8c;
  --gain-color: #00cc88;
  color-scheme: light;
  --doc-height: 100%;
  --text-primary-color: #1b1f26;
  --text-primary-solid-color: #fff;
  --text-chart-label: #a1a7b2;
  --text-chart-label-solid: #1b1f26;
  --secondary-text: #a1a7b2;
  --third-text: #1b1f26;
  --linear_gradient_bg_1: #ffffff;
  --layout-header-height: 32px;
  --menu-bg: transparent;
  --bg: #ffffff;
  --bg1: #fff;
  --bg2: #fafcfc;
  --bg3: rgba(255, 255, 255, 0.6);
  --bg4: rgba(255, 255, 255, 0.7);
  --bg5: #ffffff;
  --bg6: #ffffff;
  --bg7: #ffffff;
  --bg8: #ffffff;
  --bgl: #ffffff;
  --bg9: rgba(27, 31, 38, 0.02);
  --bg10: #ebf2f2;
  --pair-card-hover-bg: linear-gradient(180deg, #e6feff 0%, #ffffff 50.52%);
  --bgPairTitle: #f0f4f5;
  --primary-nav-bg: #fff;
  --dialog-title-bg: linear-gradient(180deg, #ffffff 0%, #f2f7f7 100%);
  --dialog-content-bg: #fafcfc;
  --pair-banner-bg: linear-gradient(180deg, #c2f2f2 0%, #e6ffe6 100%);
  --pair-banner-footer-bg: #fff;
  --scroll-bar: #dfe4e5;
  --input-background: #f7fafa;
  --input-background-focus: #ffffff;
  --card-wrapper-footer-bg: linear-gradient(180deg, #ffffff 0%, #f2f7f7 100%);
  --card-background: #ffffff;
  --text-bg: rgba(0, 191, 0, 0.1);
  --text-green: #00bf00;
  --text-bg-short: rgba(255, 77, 91, 0.1);
  --dialog-content-text: #6c7280;
  --input-border-color: #dfe4e5;
  --input-border-hover-color: #29b5bc;
  --border-color: #dfe4e5;
  --second-border-color: #f0f4f5;
  --third-border-color: #a1a7b2;
  --nav-border: #f0f4f5;
  --switch-slider-bg: #a1a7b2;
  --switch-border-color: #a1a7b2;
  --switch-bg: #ffffff;
  --switch-btn-bg: #fff;
  --switch-icon: #363d4d;
  --switch-btn-active-bg: rgba(41, 181, 188, 0.1);
  --icon-color: #a1a7b2;
  --icon-reverse-color: #fff;
  --icon-1-color: #a1a7b2;
  --icon-2-color: #1b1f26;
  --icon-4-color: #dfe4e5;
  --icon-5-color: #a1a7b2;
  --form-bg: #fff;
  --form-content-bg: #fafcfc;
  --search-input-bg: #ebf2f2;
  --search-input-show-bg: #fff;
  --use-1: #e9f7f7;
  --use-2: #f3fbfc;
  --use-3: #ffffff;
  --ripple-wrap-bg: linear-gradient(180deg, #ffffff 0%, #daf2f2 30%, #fff 50%);
  --position-card-bg: linear-gradient(180deg, rgba(41, 181, 188, 0.07) 0%, rgba(41, 181, 188, 0) 50%);
  --mobile-tab-bg: #f0f4f5;
  --expiry-bg: #ebf2f2;
  --campaign-bar-bg: rgba(255, 255, 255, 0.3);
  --campaign-bar-hover: #ffffff;
  --invitation-bg: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%);
  --invitation-border: rgba(255, 255, 255, 0.4);
  --invitation-filter: 0px 10px 20px rgba(75, 76, 77, 0.03);
  --nft-message-bg: #f0f4f5;
  --rule-arrow-bg: #a1a7b2;
  --leader-ship-border: #dfe4e5;
  --invitation-input-bg: rgba(255, 255, 255, 0.4);
  --nft-skeleton-bg: linear-gradient(90deg, #e7eced 48.96%, #f5f9fa 84.11%, #e7eced 100%);
  --leader-btn-bg: #475266;
  --leader-container-bg: #ffffff;
  --leader-title-bg: linear-gradient(180deg, #196e73 0%, #142933 100%), #1b1f26;
  --leader-ship-header-border: #f0f4f5;
  --header-dropdown-shadow: 0px 10px 20px rgba(75, 76, 77, 0.03);
  --guidance-close-bg: rgba(0, 0, 0, 0.1);
}
@media (prefers-color-scheme: dark) {
  :root {
    --primary-color: #29b5bc;
    --primary-secondary-color: #1f8599;
    --danger-color: #ff4d6a;
    --warning-color: #ffaa00;
    --success-color: #00bf80;
    --btn-linear-border-color: linear-gradient(to right, #b294ff, #57e6e6);
    --text-color: #1b1f26;
    --text-gray-color2: #a1a7b2;
    --text2: #a1a7b2;
    --text-chart-label: rgba(255, 255, 255, 0.6);
    --text-chart-label-solid: #fff;
    --link-color:  [primary];
    --link-selected-color:  [primary];
    --modal-close-color: #363d4d;
    --green1: #52c6cc;
    --green2: #00bf80;
    --green4: #00bf00;
    --red1: #ff4d6a;
    --red2: #ff4d5b;
    --blue1: #29b5bc;
    --favorite: #ffaa00;
    --primary_hover: #2fd1d9;
    --primary_block_hover: #4dc7c9;
    --secondary-color: #6c7280;
    --secondary_hover: #363d4d;
    --success_hover: #00d991;
    --danger_hover: #ff6680;
    --primary_hover_bg: rgba(41, 181, 188, 0.1);
    --secondary_hover_bg: rgba(41, 181, 188, 0.1);
    --success_hover_bg: rgba(0, 191, 128, 0.1);
    --danger_hover_bg: rgba(255, 77, 106, 0.1);
    --ghost_disabled: #252b36;
    --solid_disabled: #474d59;
    --card-wrapper-sub-bg: #f0f4f5;
    --input-background: #252b36;
    --input-background-focus: #1b1f26;
    --trade-buy-button: #00bf00;
    --trade-sell-button: #ff4d5b;
    --btn-disabled-background: #dadee6;
    --btn-disabled-text-color: #ffffff;
    --primary-bg: rgba(41, 181, 188, 0.07);
    --card-wrapper-footer-bg: linear-gradient(180deg, #ffffff 0%, #f2f7f7 100%);
    --body-bg: linear-gradient(180deg, #f2f7f7 0%, #ffffff 100%);
    --chart-tooltip-background: rgba(255, 255, 255, 0.9);
    --slider-bar-dot: #dfe4e5;
    --lose-color: #ff8c8c;
    --gain-color: #00cc88;
    color-scheme: dark;
    --link-color: #ffffff;
    --link-selected-color: #29b5bc;
    --link-hover: #ffffff;
    --link-visited: #ffffff;
    --border-color: #363d4d;
    --text-primary-solid-color: #1b1f26;
    --secondary-text: #6c7280;
    --third-text: #6c7280;
    --secondary-color: #ffffff;
    --text-blue: #59f;
    --text2: #6c7280;
    --text3: #52c6cc;
    --text4: rgba(255, 255, 255, 0.7);
    --text-color: #ffffff;
    --text-color-black: #1b1f26;
    --text-gray-color: #6c7280;
    --text-primary-color: #ffffff;
    --text-bg: rgba(0, 191, 128, 0.1);
    --text-bg-short: rgba(255, 77, 91, 0.1);
    --dialog-content-text: #fff;
    --tick-body: #e5e5e5;
    --tick-active: #00bfbf;
    --modal-close-color: #ffffff;
    --campaign-bar-hover: #ffffff;
    --nav-border: #13161c;
    --bg: #1b1f26;
    --bg1: #13161c;
    --bg2: #1b1f26;
    --bg4: #363d4d;
    --bg5: #2d333d;
    --bg6: #484d5a;
    --bg7: #2c2c2c;
    --bg8: #039da5;
    --bgl: linear-gradient(180deg, #003133 0%, #1b1f26 50.52%);
    --bg9: #222429;
    --bg10: #1b1f26;
    --pair-card-hover-bg: linear-gradient(180deg, #0a3333 0%, #1b1f26 50%);
    --bgPairTitle: #252b36;
    --primary-nav-bg: #13161c;
    --dialog-title-bg: linear-gradient(180deg, #2d333d 0%, #262b33 100%);
    --dialog-content-bg: #2d333d;
    --pair-banner-bg: linear-gradient(180deg, #142e33 0%, #131d26 100%);
    --pair-banner-footer-bg: #13161c;
    --scroll-bar: #363d4d;
    --card-background: #2d333d;
    --card-wrapper-footer-bg: none;
    --card-wrapper-sub-bg: #252b36;
    --body-bg: linear-gradient(180deg, #22262e 0%, #1b1f26 100%);
    --linear_gradient_bg_1: linear-gradient(180deg, #2d333d 0%, #262b33 100%);
    --input-border-color: var(--border-color);
    --second-border-color: #252b36;
    --third-border-color: #363d4d;
    --switch-slider-bg: #ffffff;
    --switch-border-color: #ffffff;
    --switch-bg: #1b1f26;
    --switch-btn-bg: #363d4d;
    --switch-icon: #fff;
    --switch-btn-active-bg: rgba(41, 181, 188, 0.07);
    --icon-color: #fff;
    --icon-reverse-color: #6c7280;
    --icon-1-color: #475266;
    --icon-2-color: #ffffff;
    --icon-4-color: #6c7280;
    --icon-5-color: #6c7280;
    --form-bg: #2d333d;
    --form-content-bg: #1b1f26;
    --search-input-bg: #1b1f26;
    --search-input-show-bg: #1b1f26;
    --chart-tooltip-background: rgba(0, 0, 0, 0.8);
    --btn-disabled-background: #474d59;
    --btn-disabled-text-color: rgba(255, 255, 255, 0.2);
    --slider-bar-dot: #475266;
    --use-1: #1c2b32;
    --use-2: #1b242b;
    --use-3: #1b1f26;
    --ripple-wrap-bg: linear-gradient(180deg, #1b1f26 0%, #1d3138 30%, #1b1f26 50%);
    --position-card-bg: linear-gradient(180deg, rgba(41, 181, 188, 0.07) 0%, rgba(41, 181, 188, 0) 50%);
    --mobile-tab-bg: #252b36;
    --expiry-bg: #1b1f26;
    --campaign-bar-bg: rgba(27, 31, 38, 0.3);
    --campaign-bar-hover: #000000;
    --invitation-bg: linear-gradient(180deg, rgba(54, 61, 77, 0.4) 0%, rgba(54, 61, 77, 0) 100%);
    --invitation-border: rgba(54, 61, 77, 0.4);
    --nft-message-bg: #363d4d;
    --rule-arrow-bg: #6c7280;
    --leader-ship-border: #363d4d;
    --invitation-input-bg: rgba(27, 31, 38, 0.4);
    --nft-skeleton-bg: linear-gradient(90deg, #2d333d 48.96%, #363d4c 82.81%, #2d333d 100%);
    --leader-btn-bg: #1b1f26;
    --leader-container-bg: #252b36;
    --leader-title-bg: linear-gradient(198.25deg, #e6fff7 12.4%, #66ffff 87.6%), #ffffff;
    --leader-ship-header-border: #363d4d;
    --header-dropdown-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
    --guidance-close-bg: rgba(0, 0, 0, 0.2);
  }
}
html[data-theme='light-theme'] {
  --primary-color: #29b5bc;
  --primary-secondary-color: #1f8599;
  --danger-color: #ff4d6a;
  --warning-color: #ffaa00;
  --success-color: #00bf80;
  --border-color: #363d4d;
  --btn-linear-border-color: linear-gradient(to right, #b294ff, #57e6e6);
  --text-color: #1b1f26;
  --text-gray-color: #6c7280;
  --text-gray-color2: #a1a7b2;
  --text4: rgba(255, 255, 255, 0.7);
  --text3: #52c6cc;
  --text2: #a1a7b2;
  --text-chart-label: rgba(255, 255, 255, 0.6);
  --text-chart-label-solid: #fff;
  --link-color:  [primary];
  --link-selected-color:  [primary];
  --modal-close-color: #363d4d;
  --green1: #52c6cc;
  --green2: #00bf80;
  --green4: #00bf00;
  --red1: #ff4d6a;
  --red2: #ff4d5b;
  --blue1: #29b5bc;
  --favorite: #ffaa00;
  --primary_hover: #2fd1d9;
  --primary_block_hover: #4dc7c9;
  --secondary-color: #6c7280;
  --secondary_hover: #363d4d;
  --success_hover: #00d991;
  --danger_hover: #ff6680;
  --primary_hover_bg: rgba(41, 181, 188, 0.1);
  --secondary_hover_bg: rgba(41, 181, 188, 0.1);
  --success_hover_bg: rgba(0, 191, 128, 0.1);
  --danger_hover_bg: rgba(255, 77, 106, 0.1);
  --ghost_disabled: #252b36;
  --solid_disabled: #474d59;
  --card-wrapper-sub-bg: #f0f4f5;
  --input-background: #252b36;
  --input-background-focus: #1b1f26;
  --trade-buy-button: #00bf00;
  --trade-sell-button: #ff4d5b;
  --btn-disabled-background: #dadee6;
  --btn-disabled-text-color: #ffffff;
  --primary-bg: rgba(41, 181, 188, 0.07);
  --body-bg: linear-gradient(180deg, #f2f7f7 0%, #ffffff 100%);
  --chart-tooltip-background: rgba(255, 255, 255, 0.9);
  --slider-bar-dot: #dfe4e5;
  --lose-color: #ff8c8c;
  --gain-color: #00cc88;
  color-scheme: light;
  --doc-height: 100%;
  --text-primary-color: #1b1f26;
  --text-primary-solid-color: #fff;
  --text-chart-label: #a1a7b2;
  --text-chart-label-solid: #1b1f26;
  --secondary-text: #a1a7b2;
  --third-text: #1b1f26;
  --linear_gradient_bg_1: #ffffff;
  --layout-header-height: 32px;
  --menu-bg: transparent;
  --bg: #ffffff;
  --bg1: #fff;
  --bg2: #fafcfc;
  --bg3: rgba(255, 255, 255, 0.6);
  --bg4: rgba(255, 255, 255, 0.7);
  --bg5: #ffffff;
  --bg6: #ffffff;
  --bg7: #ffffff;
  --bg8: #ffffff;
  --bgl: #ffffff;
  --bg9: rgba(27, 31, 38, 0.02);
  --bg10: #ebf2f2;
  --pair-card-hover-bg: linear-gradient(180deg, #e6feff 0%, #ffffff 50.52%);
  --bgPairTitle: #f0f4f5;
  --primary-nav-bg: #fff;
  --dialog-title-bg: linear-gradient(180deg, #ffffff 0%, #f2f7f7 100%);
  --dialog-content-bg: #fafcfc;
  --pair-banner-bg: linear-gradient(180deg, #c2f2f2 0%, #e6ffe6 100%);
  --pair-banner-footer-bg: #fff;
  --scroll-bar: #dfe4e5;
  --input-background: #f7fafa;
  --input-background-focus: #ffffff;
  --card-wrapper-footer-bg: linear-gradient(180deg, #ffffff 0%, #f2f7f7 100%);
  --card-background: #ffffff;
  --text-bg: rgba(0, 191, 0, 0.1);
  --text-green: #00bf00;
  --text-bg-short: rgba(255, 77, 91, 0.1);
  --dialog-content-text: #6c7280;
  --input-border-color: #dfe4e5;
  --input-border-hover-color: #29b5bc;
  --border-color: #dfe4e5;
  --second-border-color: #f0f4f5;
  --third-border-color: #a1a7b2;
  --nav-border: #f0f4f5;
  --switch-slider-bg: #a1a7b2;
  --switch-border-color: #a1a7b2;
  --switch-bg: #ffffff;
  --switch-btn-bg: #fff;
  --switch-icon: #363d4d;
  --switch-btn-active-bg: rgba(41, 181, 188, 0.1);
  --icon-color: #a1a7b2;
  --icon-reverse-color: #fff;
  --icon-1-color: #a1a7b2;
  --icon-2-color: #1b1f26;
  --icon-4-color: #dfe4e5;
  --icon-5-color: #a1a7b2;
  --form-bg: #fff;
  --form-content-bg: #fafcfc;
  --search-input-bg: #ebf2f2;
  --search-input-show-bg: #fff;
  --use-1: #e9f7f7;
  --use-2: #f3fbfc;
  --use-3: #ffffff;
  --ripple-wrap-bg: linear-gradient(180deg, #ffffff 0%, #daf2f2 30%, #fff 50%);
  --position-card-bg: linear-gradient(180deg, rgba(41, 181, 188, 0.07) 0%, rgba(41, 181, 188, 0) 50%);
  --mobile-tab-bg: #f0f4f5;
  --expiry-bg: #ebf2f2;
  --campaign-bar-bg: rgba(255, 255, 255, 0.3);
  --campaign-bar-hover: #ffffff;
  --invitation-bg: linear-gradient(180deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%);
  --invitation-border: rgba(255, 255, 255, 0.4);
  --invitation-filter: 0px 10px 20px rgba(75, 76, 77, 0.03);
  --nft-message-bg: #f0f4f5;
  --rule-arrow-bg: #a1a7b2;
  --leader-ship-border: #dfe4e5;
  --invitation-input-bg: rgba(255, 255, 255, 0.4);
  --nft-skeleton-bg: linear-gradient(90deg, #e7eced 48.96%, #f5f9fa 84.11%, #e7eced 100%);
  --leader-btn-bg: #475266;
  --leader-container-bg: #ffffff;
  --leader-title-bg: linear-gradient(180deg, #196e73 0%, #142933 100%), #1b1f26;
  --leader-ship-header-border: #f0f4f5;
  --header-dropdown-shadow: 0px 10px 20px rgba(75, 76, 77, 0.03);
  --guidance-close-bg: rgba(0, 0, 0, 0.1);
}
html[data-theme='dark-theme'] {
  --primary-color: #29b5bc;
  --primary-secondary-color: #1f8599;
  --danger-color: #ff4d6a;
  --warning-color: #ffaa00;
  --success-color: #00bf80;
  --btn-linear-border-color: linear-gradient(to right, #b294ff, #57e6e6);
  --text-color: #1b1f26;
  --text-gray-color2: #a1a7b2;
  --text2: #a1a7b2;
  --text-chart-label: rgba(255, 255, 255, 0.6);
  --text-chart-label-solid: #fff;
  --link-color:  [primary];
  --link-selected-color:  [primary];
  --modal-close-color: #363d4d;
  --green1: #52c6cc;
  --green2: #00bf80;
  --green4: #00bf00;
  --red1: #ff4d6a;
  --red2: #ff4d5b;
  --blue1: #29b5bc;
  --favorite: #ffaa00;
  --primary_hover: #2fd1d9;
  --primary_block_hover: #4dc7c9;
  --secondary-color: #6c7280;
  --secondary_hover: #363d4d;
  --success_hover: #00d991;
  --danger_hover: #ff6680;
  --primary_hover_bg: rgba(41, 181, 188, 0.1);
  --secondary_hover_bg: rgba(41, 181, 188, 0.1);
  --success_hover_bg: rgba(0, 191, 128, 0.1);
  --danger_hover_bg: rgba(255, 77, 106, 0.1);
  --ghost_disabled: #252b36;
  --solid_disabled: #474d59;
  --card-wrapper-sub-bg: #f0f4f5;
  --input-background: #252b36;
  --input-background-focus: #1b1f26;
  --trade-buy-button: #00bf00;
  --trade-sell-button: #ff4d5b;
  --btn-disabled-background: #dadee6;
  --btn-disabled-text-color: #ffffff;
  --primary-bg: rgba(41, 181, 188, 0.07);
  --card-wrapper-footer-bg: linear-gradient(180deg, #ffffff 0%, #f2f7f7 100%);
  --body-bg: linear-gradient(180deg, #f2f7f7 0%, #ffffff 100%);
  --chart-tooltip-background: rgba(255, 255, 255, 0.9);
  --slider-bar-dot: #dfe4e5;
  --lose-color: #ff8c8c;
  --gain-color: #00cc88;
  color-scheme: dark;
  --link-color: #ffffff;
  --link-selected-color: #29b5bc;
  --link-hover: #ffffff;
  --link-visited: #ffffff;
  --border-color: #363d4d;
  --text-primary-solid-color: #1b1f26;
  --secondary-text: #6c7280;
  --third-text: #6c7280;
  --secondary-color: #ffffff;
  --text-blue: #59f;
  --text2: #6c7280;
  --text3: #52c6cc;
  --text4: rgba(255, 255, 255, 0.7);
  --text-color: #ffffff;
  --text-color-black: #1b1f26;
  --text-gray-color: #6c7280;
  --text-primary-color: #ffffff;
  --text-bg: rgba(0, 191, 128, 0.1);
  --text-bg-short: rgba(255, 77, 91, 0.1);
  --dialog-content-text: #fff;
  --tick-body: #e5e5e5;
  --tick-active: #00bfbf;
  --modal-close-color: #ffffff;
  --campaign-bar-hover: #ffffff;
  --nav-border: #13161c;
  --bg: #1b1f26;
  --bg1: #13161c;
  --bg2: #1b1f26;
  --bg4: #363d4d;
  --bg5: #2d333d;
  --bg6: #484d5a;
  --bg7: #2c2c2c;
  --bg8: #039da5;
  --bgl: linear-gradient(180deg, #003133 0%, #1b1f26 50.52%);
  --bg9: #222429;
  --bg10: #1b1f26;
  --pair-card-hover-bg: linear-gradient(180deg, #0a3333 0%, #1b1f26 50%);
  --bgPairTitle: #252b36;
  --primary-nav-bg: #13161c;
  --dialog-title-bg: linear-gradient(180deg, #2d333d 0%, #262b33 100%);
  --dialog-content-bg: #2d333d;
  --pair-banner-bg: linear-gradient(180deg, #142e33 0%, #131d26 100%);
  --pair-banner-footer-bg: #13161c;
  --scroll-bar: #363d4d;
  --card-background: #2d333d;
  --card-wrapper-footer-bg: none;
  --card-wrapper-sub-bg: #252b36;
  --body-bg: linear-gradient(180deg, #22262e 0%, #1b1f26 100%);
  --linear_gradient_bg_1: linear-gradient(180deg, #2d333d 0%, #262b33 100%);
  --input-border-color: var(--border-color);
  --second-border-color: #252b36;
  --third-border-color: #363d4d;
  --switch-slider-bg: #ffffff;
  --switch-border-color: #ffffff;
  --switch-bg: #1b1f26;
  --switch-btn-bg: #363d4d;
  --switch-icon: #fff;
  --switch-btn-active-bg: rgba(41, 181, 188, 0.07);
  --icon-color: #fff;
  --icon-reverse-color: #6c7280;
  --icon-1-color: #475266;
  --icon-2-color: #ffffff;
  --icon-4-color: #6c7280;
  --icon-5-color: #6c7280;
  --form-bg: #2d333d;
  --form-content-bg: #1b1f26;
  --search-input-bg: #1b1f26;
  --search-input-show-bg: #1b1f26;
  --chart-tooltip-background: rgba(0, 0, 0, 0.8);
  --btn-disabled-background: #474d59;
  --btn-disabled-text-color: rgba(255, 255, 255, 0.2);
  --slider-bar-dot: #475266;
  --use-1: #1c2b32;
  --use-2: #1b242b;
  --use-3: #1b1f26;
  --ripple-wrap-bg: linear-gradient(180deg, #1b1f26 0%, #1d3138 30%, #1b1f26 50%);
  --position-card-bg: linear-gradient(180deg, rgba(41, 181, 188, 0.07) 0%, rgba(41, 181, 188, 0) 50%);
  --mobile-tab-bg: #252b36;
  --expiry-bg: #1b1f26;
  --campaign-bar-bg: rgba(27, 31, 38, 0.3);
  --campaign-bar-hover: #000000;
  --invitation-bg: linear-gradient(180deg, rgba(54, 61, 77, 0.4) 0%, rgba(54, 61, 77, 0) 100%);
  --invitation-border: rgba(54, 61, 77, 0.4);
  --nft-message-bg: #363d4d;
  --rule-arrow-bg: #6c7280;
  --leader-ship-border: #363d4d;
  --invitation-input-bg: rgba(27, 31, 38, 0.4);
  --nft-skeleton-bg: linear-gradient(90deg, #2d333d 48.96%, #363d4c 82.81%, #2d333d 100%);
  --leader-btn-bg: #1b1f26;
  --leader-container-bg: #252b36;
  --leader-title-bg: linear-gradient(198.25deg, #e6fff7 12.4%, #66ffff 87.6%), #ffffff;
  --leader-ship-header-border: #363d4d;
  --header-dropdown-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  --guidance-close-bg: rgba(0, 0, 0, 0.2);
}
.linear-gradient-bg {
  background: linear-gradient(45deg, #8095ff 0%, #29b5bc 50%, #1262b2 100%) 0% 0% repeat;
  background-size: 200% 100%;
}
.remove-scrollbar {
  scrollbar-width: none;
  /* Firefox */
}
.remove-scrollbar::-webkit-scrollbar {
  display: none;
  /* Chrome Safari */
}
* {
  box-sizing: border-box;
}
*:before {
  box-sizing: border-box;
}
*:after {
  box-sizing: border-box;
}
html,
body {
  font-family: Poppins, 'Roboto', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-style: normal;
  font-size: 14px;
  margin: 0;
  width: 100%;
  height: 100%;
}
ul {
  list-style-type: none;
  margin: 0;
}
html.mobile {
  font-size: 12px;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #041424;
  color: white;
  overflow-x: auto;
  --duration: 0.5s;
  --timing: ease;
  transition: color var(--duration) var(--timing), background-color var(--duration) var(--timing);
}
a {
  color: var(--link-color);
}
a .selected {
  color: var(--link-selected-color);
}
a:hover {
  color: #29b5bc;
}
::selection {
  background: #29b5bc;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background: var(--scroll-bar);
}
.ant-spin-blur:after {
  background-color: var(--bg5);
  opacity: 0;
}
.mobile > body {
  height: 100vh;
  height: -webkit-fill-available;
  overflow: hidden;
  position: fixed;
  left: 0;
  top: 0;
}
.number-value-container {
  display: flex;
  align-items: baseline;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}
#root {
  height: 100%;
  width: 100%;
}
#root > div {
  height: 100%;
  width: 100%;
}
#root > div .App {
  height: 100%;
  width: 100%;
}
